import { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import { useToast } from '@context/toastContext';
import { getErrorMessage } from '@api/handleApiError';
import { getStatisticsAPI, searchTransactionsAPI } from '@api/main';
import LatestTransactions from '@components/dashboard/LatestTransactions';
import TotalCard from '@components/dashboard/TotalCard';

const Dashboard = () => {
  const showToast = useToast();
  const [statistics, setStatistics] = useState({
    today: {},
    thisWeek: {},
    last7Days: {},
    thisMonth: {}
  });
  const [transactions, setTransactions] = useState([]);

  useEffect(async () => {
    try {
      // Get total cards data
      const result = await getStatisticsAPI();
      setStatistics(result.data);
      // Get latest transactions
      const transactionResult = await searchTransactionsAPI(
        moment().add(-7, 'days').valueOf(),
        moment().valueOf(),
        0,
        0,
        '',
        '',
        10,
        1
      );
      setTransactions(transactionResult.data.pageData);
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | GoGame Merchant Portal</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item xl={3} sm={6} xs={12}>
              <TotalCard
                title="Today so far"
                totalAmount={statistics.today.totalAmount}
                totalTransactions={statistics.today.transactionCount}
              />
            </Grid>
            <Grid item xl={3} sm={6} xs={12}>
              <TotalCard
                title="Last 7 days"
                totalAmount={statistics.last7Days.totalAmount}
                totalTransactions={statistics.last7Days.transactionCount}
              />
            </Grid>
            <Grid item xl={3} sm={6} xs={12}>
              <TotalCard
                title="This week"
                totalAmount={statistics.thisWeek.totalAmount}
                totalTransactions={statistics.thisWeek.transactionCount}
              />
            </Grid>
            <Grid item xl={3} sm={6} xs={12}>
              <TotalCard
                title="This month"
                totalAmount={statistics.thisMonth.totalAmount}
                totalTransactions={statistics.thisMonth.transactionCount}
              />
            </Grid>
            <Grid item xs={12}>
              <LatestTransactions transactions={transactions} viewAllUrl="/app/transactions" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
