export const UserTypeEnum = {
  SuperAdmin: 0,
  EndUser: 1,
  Merchant: 2
};

export const TransactionStatusEnum = {
  WaitForPayment: 1000,
  PaymentProcessing: 3000,
  Success: 5000,
  Canceled: 9000,
  Error: 9999
};

export const TransactionStatusStyle = {
  1000: {
    label: 'Wait for payment',
    color: 'warning'
  },
  3000: {
    label: 'Payment processing',
    color: 'warning'
  },
  5000: {
    label: 'Success',
    color: 'success'
  },
  9000: {
    label: 'Canceled',
    color: 'error'
  },
  9800: {
    label: 'Wrong amount',
    color: 'error'
  },
  9999: {
    label: 'Error',
    color: 'error'
  }
};

export const TransactionStatusOptions = [
  {
    value: 0,
    label: 'All'
  },
  {
    value: 1000,
    label: 'Wait for payment'
  },
  {
    value: 3000,
    label: 'Payment processing'
  },
  {
    value: 5000,
    label: 'Success'
  },
  {
    value: 9000,
    label: 'Canceled'
  },
  {
    value: 9800,
    label: 'Wrong amount'
  },
  {
    value: 9999,
    label: 'Error'
  }
];

export const PaymentGatewayEnum = {
  BaoKim: 1,
  Gate: 2
};

export const PaymentGatewayOptions = [
  {
    value: 0,
    label: 'All'
  },
  {
    value: 1,
    label: 'Bao Kim'
  },
  {
    value: 2,
    label: 'GATE'
  },
];
