import { useState, useEffect, useCallback } from 'react';
import { searchAllTransactionsAPI, getAllMerchantsAPI, getAllGamesAPI, checkPaymentStatusAPI } from '@api/main';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Menu,
  MenuItem,
  CircularProgress
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import debounce from 'lodash/debounce';
import XLSX from 'xlsx';

import { useToast } from '@context/toastContext';
import { PaymentGatewayOptions, TransactionStatusOptions, TransactionStatusStyle } from '@utils/constants';
import { getErrorMessage } from '@api/handleApiError';

const _defaultFilter = {
  fromDate: moment().startOf('month'),
  toDate: moment().endOf('month'),
  merchant: 0,
  game: 0,
  paymentGateway: 0,
  status: 0,
  transactionId: '',
  merchantOrderId: ''
};

const tableHeaders = {
  transactionId: 'Transaction ID',
  merchantOrderId: 'Merchant Order ID',
  applicationName: 'Game',
  totalAmount: 'Total Amount',
  orderDescription: 'Description',
  paymentGateway: 'Gateway',
  status: 'Status',
  createdAtUnix: 'Created Time',
  actions: 'Actions'
};

const rowsPerPageOptions = [10, 20, 50, 100];

const ActionMenu = ({ onActionCheck }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={onActionCheck}>Check</MenuItem>
      </Menu>
    </div>
  );
};

const AdminTransactions = () => {
  const showToast = useToast();
  const [filterValues, setFilterValues] = useState(_defaultFilter);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState(_defaultFilter);
  const [transactionCount, setTransactionCount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    debounce(async (filter, pageSize, pageNumber) => {
      try {
        const result = await searchAllTransactionsAPI(
          filter.fromDate.valueOf(),
          filter.toDate.valueOf(),
          filter.merchant,
          filter.game,
          filter.paymentGateway,
          filter.status,
          filter.transactionId,
          filter.merchantOrderId,
          pageSize,
          pageNumber
        );
        setTransactionCount(result.data.paging.totalItem);
        setTransactions(result.data.pageData);
      } catch (error) {
        showToast({
          type: 'error',
          message: getErrorMessage(error)
        });
      }
    }, 400),
    []
  );

  useEffect(() => {
    const loadLists = async () => {
      try {
        const merchantResult = await getAllMerchantsAPI();
        setMerchants([{ merchantId: 0, merchantName: 'All' }, ...merchantResult.data]);

        const gameResult = await getAllGamesAPI();
        setGames([{ gameId: 0, gameName: 'All' }, ...gameResult.data]);
      } catch (error) {
        showToast({
          type: 'error',
          message: getErrorMessage(error)
        });
      }
    };
    loadLists();
  }, []);

  useEffect(() => {
    fetchData(filterData, rowsPerPage, page + 1);
  }, [filterData, rowsPerPage, page]);

  const handleExport = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      const results = [];
      for (let i = 1; ; i++) {
        // eslint-disable-next-line no-await-in-loop -- We need to check page data size to know when to stop.
        const result = await searchAllTransactionsAPI(
          filterData.fromDate.valueOf(),
          filterData.toDate.valueOf(),
          filterData.merchant,
          filterData.game,
          filterData.paymentGateway,
          filterData.status,
          filterData.transactionId,
          filterData.merchantOrderId,
          rowsPerPageOptions[rowsPerPageOptions.length - 1],
          page + i
        );
        if (result.data.pageData.length === 0) break;
        result.data.pageData.map((transaction) => {
          return results.push({
            [tableHeaders.transactionId]: transaction.transactionId,
            [tableHeaders.merchantOrderId]: transaction.merchantOrderId,
            [tableHeaders.applicationName]: transaction.applicationName,
            [tableHeaders.totalAmount]: transaction.totalAmount,
            [tableHeaders.orderDescription]: transaction.orderDescription,
            [tableHeaders.status]: TransactionStatusStyle[transaction.status].label,
            [tableHeaders.paymentGateway]: transaction.paymentGateway,
            [tableHeaders.createdAtUnix]: moment(transaction.createdAtUnix).format('yyyy-MM-DD HH:mm:ss')
          });
        });
      }
      const name = `gogame_transactions_${filterData.fromDate.format('yyyyMMDD')}_${filterData.toDate.format(
        'yyyyMMDD'
      )}.xlsx`;
      const wb = XLSX.utils.json_to_sheet(results);
      const wbout = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wbout, wb, 'Sheet1');
      XLSX.writeFile(wbout, name);
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    } finally {
      setLoading(false);
    }
  }, [filterData]);

  const handleChange = (event) => {
    setFilterValues({
      ...filterValues,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeDate = (key, value) => {
    if (key === 'fromDate') {
      setFilterValues({
        ...filterValues,
        fromDate: value.startOf('day'),
        toDate: value > filterValues.toDate ? value.endOf('day') : filterValues.toDate
      });
    } else if (key === 'toDate') {
      setFilterValues({
        ...filterValues,
        fromDate: value < filterValues.fromDate ? value.startOf('day') : filterValues.fromDate,
        toDate: value.endOf('day')
      });
    }
  };

  const handleSearch = () => {
    setFilterData({ ...filterValues });
  };

  const handleClear = () => {
    setFilterValues(_defaultFilter);
    setFilterData(_defaultFilter);
  };

  const handleLimitChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleCheck = (transactionId) => {
    checkPaymentStatusAPI(transactionId);
  };

  return (
    <>
      <Helmet>
        <title>Transactions | GoGame Merchant Portal</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  flex: 1
                }}
              >
                Transactions
              </Typography>
              <Button sx={{ mx: 1 }} onClick={handleExport} disabled={loading}>
                Export
              </Button>
              <CircularProgress size="1.25rem" sx={{ alignSelf: 'center', display: loading ? 'block' : 'none' }} />
            </Box>
            <Box sx={{ mt: 3 }}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                      <DesktopDatePicker
                        label="From Date"
                        name="fromDate"
                        inputFormat="DD/MM/yyyy"
                        value={filterValues.fromDate}
                        onChange={(value) => handleChangeDate('fromDate', value)}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <DesktopDatePicker
                        label="To Date"
                        name="toDate"
                        inputFormat="DD/MM/yyyy"
                        value={filterValues.toDate}
                        onChange={(value) => handleChangeDate('toDate', value)}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Select Merchant"
                        name="merchant"
                        onChange={handleChange}
                        select
                        value={filterValues.merchant}
                        variant="outlined"
                      >
                        {merchants.map((merchant) => (
                          <MenuItem key={merchant.merchantId} value={merchant.merchantId}>
                            {merchant.merchantName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Select Game"
                        name="game"
                        onChange={handleChange}
                        select
                        value={filterValues.game}
                        variant="outlined"
                      >
                        {games.map((game) => (
                          <MenuItem key={game.gameId} value={game.gameId}>
                            {game.gameName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Select Payment Gateway"
                        name="paymentGateway"
                        onChange={handleChange}
                        select
                        value={filterValues.paymentGateway}
                        variant="outlined"
                      >
                        {PaymentGatewayOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Select Status"
                        name="status"
                        onChange={handleChange}
                        select
                        value={filterValues.status}
                        variant="outlined"
                      >
                        {TransactionStatusOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Transaction ID"
                        name="transactionId"
                        onChange={handleChange}
                        value={filterValues.transactionId}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Merchant Order ID"
                        name="merchantOrderId"
                        onChange={handleChange}
                        value={filterValues.merchantOrderId}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          py: 1
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ mx: 1 }}
                          onClick={handleSearch}
                          disabled={loading}
                        >
                          Search
                        </Button>
                        <Button color="primary" variant="outlined" onClick={handleClear}>
                          Clear
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{tableHeaders.applicationName}</TableCell>
                        <TableCell>{tableHeaders.transactionId}</TableCell>
                        <TableCell>{tableHeaders.merchantOrderId}</TableCell>
                        <TableCell>{tableHeaders.totalAmount}</TableCell>
                        <TableCell>{tableHeaders.orderDescription}</TableCell>
                        <TableCell>{tableHeaders.status}</TableCell>
                        <TableCell>{tableHeaders.paymentGateway}</TableCell>
                        <TableCell>{tableHeaders.createdAtUnix}</TableCell>
                        <TableCell>{tableHeaders.actions}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactions.map((transaction) => (
                        <TableRow hover key={transaction.transactionId}>
                          <TableCell>{transaction.applicationName}</TableCell>
                          <TableCell>{transaction.transactionId}</TableCell>
                          <TableCell>{transaction.merchantOrderId}</TableCell>
                          <TableCell>{new Intl.NumberFormat('vi').format(transaction.totalAmount)}</TableCell>
                          <TableCell>{transaction.orderDescription}</TableCell>
                          <TableCell>
                            <Chip
                              label={TransactionStatusStyle[transaction.status].label}
                              color={TransactionStatusStyle[transaction.status].color}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>{transaction.paymentGateway}</TableCell>
                          <TableCell>{moment(transaction.createdAtUnix).format('yyyy-MM-DD HH:mm:ss')}</TableCell>
                          <TableCell>
                            <ActionMenu onActionCheck={() => handleCheck(transaction.transactionId)} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                count={transactionCount}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AdminTransactions;
