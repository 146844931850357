import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { UserTypeEnum } from '@utils/constants';

const Home = () => {
  const { user } = useSelector((state) => state.auth);

  if (!!user && user.userTypeId === UserTypeEnum.SuperAdmin) {
    return <Navigate to="/sa/dashboard" />;
  }

  if (!!user && user.userTypeId === UserTypeEnum.Merchant) {
    return <Navigate to="/app/dashboard" />;
  }

  return <Navigate to="/login" />;
};

export default Home;
