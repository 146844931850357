import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, Typography, Divider } from '@mui/material';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  LogOut as LogOutIcon,
  Activity as ActivityIcon,
  Box as BoxIcon,
  Info as InfoIcon
} from 'react-feather';
import { useDispatch } from 'react-redux';

import { logoutAC } from '@store/actions/auth';
import NavItem from './NavItem';
import NavExternalLink from './NavExternalLink';

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/transactions',
    icon: ActivityIcon,
    title: 'Transactions'
  },
  {
    href: '/app/games',
    icon: BoxIcon,
    title: 'Games'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings'
  }
];

const DashboardSidebar = ({ name, onMobileClose, openMobile }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Hidden lgUp>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            p: 2
          }}
        >
          <Typography color="textPrimary" variant="h4">
            {name}
          </Typography>
        </Box>
        <Divider />
      </Hidden>
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
          ))}
          <NavExternalLink
            href="/static/payment_integration_20211109.pdf"
            key="Document"
            title="Document"
            icon={InfoIcon}
          />
        </List>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <NavItem href="#" key="logout" title="Logout" icon={LogOutIcon} onClick={() => dispatch(logoutAC())} />
      </Box>
      <Box sx={{ alignItems: 'flex-end', display: 'flex', flex: 1, p: 2 }}>
        <Typography color="textSecondary" variant="body2">
          © 2023 by Blue Bamboo Technology JSC
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
