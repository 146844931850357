import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TransactionStatusStyle } from '@utils/constants';

const LatestTransactions = ({ transactions, viewAllUrl }) => (
  <Card>
    <CardHeader title="Latest Transactions" />
    <Divider />
    <PerfectScrollbar>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Game</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Merchant Order ID</TableCell>
              <TableCell>Total Amount</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Updated Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow hover key={transaction.transactionId}>
                <TableCell>{transaction.applicationName}</TableCell>
                <TableCell>{transaction.transactionId}</TableCell>
                <TableCell>{transaction.merchantOrderId}</TableCell>
                <TableCell>{new Intl.NumberFormat('vi').format(transaction.totalAmount)}</TableCell>
                <TableCell>{transaction.orderDescription}</TableCell>
                <TableCell>
                  <Chip
                    label={TransactionStatusStyle[transaction.status].label}
                    color={TransactionStatusStyle[transaction.status].color}
                    size="small"
                  />
                </TableCell>
                <TableCell>{moment(transaction.createdAtUnix).format('yyyy-MM-DD HH:mm:ss')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}
    >
      <Button href={viewAllUrl} color="primary" endIcon={<ArrowRightIcon />} size="small" variant="text">
        View all
      </Button>
    </Box>
  </Card>
);

export default LatestTransactions;
